import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GtmDataLayer, ToGtmDataLayer } from "wmk-link";
import { colors } from "../../ui/colors";

const BurgerWrap = styled.button`
  position: relative;
  padding: 0 3vw 0 0;
  top: 0;
  height: 40px;
  width: 40px;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.25s ease;
  background: none;
  border: none;
`;

interface BurgerBarProps {
  lineBaseStyle: (is?: "true") => string;
  isStuck?: "true";
  isOpen?: "true";
}

const TopBar = styled.span<BurgerBarProps>`
  ${({ lineBaseStyle, isStuck }) => lineBaseStyle(isStuck)}
  ${({ isOpen, isStuck }) =>
    isOpen && isStuck
      ? `transform: rotate(45deg); left: 0; top: 20px;`
      : isOpen
      ? `transform: rotate(45deg); left: 0; top: 20px;`
      : isStuck
      ? `left: 0; top: 10px;`
      : `left: 0; top: 7px;`}
`;
const MidBar = styled.span<BurgerBarProps>`
  ${({ lineBaseStyle, isStuck }) => lineBaseStyle(isStuck)}
  ${({ isOpen, isStuck }) =>
    isOpen && isStuck
      ? `transform: rotate(180deg); left: 0; top: 20px; opacity: 0;`
      : isOpen
      ? `transform: rotate(180deg); left: 0; top: 20px; opacity: 0;`
      : isStuck
      ? `top: 18px;`
      : `top: 20px;`}
`;
const BtmBar = styled.span<BurgerBarProps>`
  ${({ lineBaseStyle, isStuck }) => lineBaseStyle(isStuck)}
  ${({ isOpen, isStuck }) =>
    isOpen && isStuck
      ? `transform: rotate(-45deg); left: 0; top: 20px;`
      : isOpen
      ? `transform: rotate(-45deg); left: 0; top: 20px;`
      : isStuck
      ? `left: 0; top: 26px;`
      : `left: 0; top: 33px;`}
`;

const BurgerButton = ({
  isStuck,
  isOpen,
  toggle,
  tracking
}: {
  isStuck: boolean;
  isOpen: boolean;
  toggle: () => void;
  tracking?: ToGtmDataLayer;
}) => {
  const [data, setData] = useState<GtmDataLayer>();
  useEffect(() => {
    const dL = (window && window.dataLayer) || [];
    if (tracking && window && window.dataLayer) {
      setData(dL);
    }
  }, [tracking]);
  const lineBaseStyle = (isStuck?: "true") => `
  background: ${colors.white.getHex()};
  border: none;
  height: 5px;
  width: 100%;
  border-radius: 0px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.35s ease;
  cursor: pointer;
  ${
    isStuck
      ? `width: 70%;
      height: 3px;   
      top: 0.75rem;`
      : ``
  }
`;
  return (
    <BurgerWrap
      onClick={() => {
        toggle();

        return tracking && data
          ? data.push({ event: tracking.event, ...tracking.params })
          : undefined;
      }}
      // style={{ top: `${height / 2 / 2}px` }}
      aria-label={isOpen ? "Close Menu" : "Open Menu"}>
      <TopBar
        isOpen={isOpen ? "true" : undefined}
        isStuck={isStuck ? "true" : undefined}
        lineBaseStyle={lineBaseStyle}
      />
      <MidBar
        isOpen={isOpen ? "true" : undefined}
        isStuck={isStuck ? "true" : undefined}
        lineBaseStyle={lineBaseStyle}
      />
      <BtmBar
        isOpen={isOpen ? "true" : undefined}
        isStuck={isStuck ? "true" : undefined}
        lineBaseStyle={lineBaseStyle}
      />
    </BurgerWrap>
  );
};

export default BurgerButton;
