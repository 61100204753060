import * as React from "react";

const FONT_FAMILY_PRIMARY = `DM Sans, sans-serif`;
const FONT_FAMILY_ACCENT = `Farsan, sans-serif`;
const FONT_FAMILY_FOOTER = `Raleway, sans-serif`;

const FONT_WEIGHT_LIGHT = 300;
const FONT_WEIGHT_NORMAL = 400;
const FONT_WEIGHT_BOLD = 700;

export const Typography = {
  H1: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h1 style={style} ref={ref}>
        {children}
      </h1>
    )
  ),
  H2: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h2
        style={{
          fontSize: 50,
          fontFamily: FONT_FAMILY_ACCENT,
          fontWeight: FONT_WEIGHT_NORMAL,
          textTransform: "uppercase",
          ...style
        }}
        ref={ref}>
        {children}
      </h2>
    )
  ),
  H3: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h3 style={style} ref={ref}>
        {children}
      </h3>
    )
  ),
  H4: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h4 style={style} ref={ref}>
        {children}
      </h4>
    )
  ),
  H5: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h5 style={style} ref={ref}>
        {children}
      </h5>
    )
  ),
  H6: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h6 style={style} ref={ref}>
        {children}
      </h6>
    )
  ),
  P: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLDivElement>
    ) => (
      <div
        style={{
          fontSize: 14,
          fontFamily: FONT_FAMILY_PRIMARY,
          fontWeight: FONT_WEIGHT_NORMAL,
          ...style
        }}
        ref={ref}
        className="p">
        {children}
      </div>
    )
  ),
  BLOCKQUOTE: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLQuoteElement>
    ) => (
      <blockquote
        style={{
          fontSize: 20,
          fontFamily: FONT_FAMILY_PRIMARY,
          fontWeight: FONT_WEIGHT_BOLD,
          ...style
        }}
        ref={ref}>
        {children}
      </blockquote>
    )
  ),
  FOOTER: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLDivElement>
    ) => (
      <div
        style={{
          fontSize: 14,
          fontFamily: FONT_FAMILY_FOOTER,
          fontWeight: FONT_WEIGHT_LIGHT,
          ...style
        }}
        ref={ref}>
        {children}
      </div>
    )
  )
};
