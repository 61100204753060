import { WmkLink } from "wmk-link";
import * as React from "react";
import { colors } from "../../ui/colors";
import { graphql, useStaticQuery } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import { MenuQuery } from "../../../fragments/NodeMenuFields";
import styled from "styled-components";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import { Typography } from "../../ui/Typography";
import { useState, useRef, useEffect } from "react";
import BurgerButton from "./BurgerButton";
import { LinkQuery } from "../../../fragments/NodeLinkFields";

const HeaderContainer = styled(Container)<{ mobile: string }>`
  row {
    padding: 1rem 0;
  }
  .link-row {
    height: 100%;
  }
  .image-col {
    order: 2;
  }
  .contact-col {
    order: 3;
  }
  .link-col {
    justify-content: center;
    display: flex;
    align-items: center;
    blockquote {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 0 !important;
    }
    a {
      color: ${colors.white.getHex()};
      text-decoration: none;
    }
  }
  .content-col {
    order: 1;
  }
  .contact-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  @media only screen and (max-width: 768px) {
    .content-col {
      order: 2;
    }
    .image-col {
      order: 1;
    }
  }
  ${({ mobile }) =>
    mobile === "false"
      ? `.content-col {
          display: flex;
          justify-content: flex-end;
        }`
      : ``};
`;

const StyledButton = styled.div<{ reverse: boolean }>`
  display: inline-block;
  transition: all 0.3s ease;
  position: relative;
  a {
    display: inline-block;
    background: ${colors.primary.getHex()};
    color: ${colors.white.getHex()};
    padding: 0.5rem 2rem;
    &:hover {
      background: ${colors.white.getHex()};
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
      transition: all 0.3s ease;
      blockquote {
        color: ${colors.primary.getHex()};
      }
    }
    blockquote {
      margin: 0;
      padding: 0;
      display: inline-block;
    }
  }
`;

const BurgerDrawer = styled.div<{ top: number }>`
  position: fixed;
  left: 0;
  height: calc(100vh);
  background: ${colors.primary.toString()};
  width: 100%;
  top: ${({ top }) => top}px;
  z-index: 1;
  .mobile-dropdown-col {
    a {
      color: ${colors.white.getHex()};
    }
    a:hover {
      color: ${colors.primary.getHex()};
    }
    text-align: center;
  }
`;

const DesktopMenu = ({ menu }: { menu: MenuQuery }) => {
  return (
    <Row className="link-row">
      {menu.links.map((link, i) => {
        return (
          <Col md={5} className="link-col" key={link.title + i}>
            <Typography.BLOCKQUOTE>
              <WmkLink
                to={
                  link.url.includes("self://")
                    ? link.url.replace("self://", "/")
                    : link.url
                }>
                {link.linkText}
              </WmkLink>
            </Typography.BLOCKQUOTE>
          </Col>
        );
      })}
    </Row>
  );
};

const MobileBurgerMenu = ({
  menu,
  height
}: {
  menu: MenuQuery;
  height: number;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const close = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Col
        xs="auto"
        style={{
          display: "flex",
          alignItems: "center",
          padding: `0 2vh 0 0`
        }}>
        <div style={{ padding: `0` }}>
          <BurgerButton isStuck={false} isOpen={isOpen} toggle={toggle} />
        </div>
      </Col>
      {isOpen ? (
        <BurgerDrawer top={height}>
          <Container style={{ padding: `5vh 1vh` }}>
            <Row className="flex-column">
              {menu.links.map((link, i) => {
                const { title } = link;
                const target = link.target === "New Window" ? "blank" : "self";
                return (
                  <Col className="mobile-dropdown-col" key={title + i}>
                    <Typography.H2>
                      <WmkLink
                        key={title + i}
                        to={
                          link.url.includes("self://")
                            ? link.url.replace("self://", "/")
                            : link.url
                        }
                        target={target}
                        onClick={close}>
                        {link.linkText}
                      </WmkLink>
                    </Typography.H2>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </BurgerDrawer>
      ) : null}
    </>
  );
};

const Header = () => {
  const [viewWidth, setViewWidth] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
    const target = headerRef.current?.getBoundingClientRect();
    setHeaderHeight(target?.height ? target.height : 0);
  };

  const data: HeaderQuery = useStaticQuery(query);
  const logoImage = new Img(data.logo);
  return (
    <header
      style={{
        background: colors.black.getHex(),
        padding: "1rem 0",
        borderBottom: `17px solid ${colors.primary.getHex()}`
      }}
      ref={headerRef as any}>
      <HeaderContainer mobile={viewWidth > 767 ? "true" : "false"}>
        <Row>
          <Col className="image-col" xs={9} md={5}>
            <WmkLink to="/">
              <WmkImage image={logoImage} />
            </WmkLink>
          </Col>
          <Col className="content-col" xs={3} md={4}>
            {viewWidth > 767 ? (
              <DesktopMenu menu={data.menu} />
            ) : (
              <MobileBurgerMenu menu={data.mobileMenu} height={headerHeight} />
            )}
          </Col>
          {viewWidth > 767 && (
            <Col className="contact-col" md={3}>
              <StyledButton reverse={false ? true : undefined}>
                <WmkLink
                  to={
                    data.contact.url.includes("self://")
                      ? data.contact.url.replace("self://", "/")
                      : data.contact.url
                  }>
                  <Typography.BLOCKQUOTE>
                    {data.contact.linkText}
                  </Typography.BLOCKQUOTE>
                </WmkLink>
              </StyledButton>
            </Col>
          )}
        </Row>
      </HeaderContainer>
    </header>
  );
};

export default Header;

interface HeaderQuery {
  logo: ContentfulImageQuery;
  menu: MenuQuery;
  contact: LinkQuery;
  mobileMenu: MenuQuery;
}

const query = graphql`
  {
    menu: contentfulMenu(title: { eq: "Main Menu" }) {
      ...NodeMenuFields
    }
    logo: contentfulAsset(
      title: { eq: "Christmasland-Logo-RGB-Color-150dpi" }
    ) {
      ...NodeImageQuery
    }
    contact: contentfulLink(title: { eq: "Contact" }) {
      ...NodeLinkFields
    }
    mobileMenu: contentfulMenu(title: { eq: "Footer Menu" }) {
      ...NodeMenuFields
    }
  }
`;
