import { graphql, useStaticQuery } from "gatsby";
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import { Copyright } from "wmk-layout";
import { WmkLink } from "wmk-link";
import { MenuQuery } from "../../fragments/NodeMenuFields";
import { SiteMetaDataFields } from "../../fragments/NodeSiteMetadataFields";
import { colors } from "../ui/colors";
import { Typography } from "../ui/Typography";
import {
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn
} from "react-icons/fa";

const FooterWrapper = styled.footer`
  border-top: 17px solid ${colors.primary.getHex()};
  background: ${colors.black.getHex()};
  color: ${colors.white.getHex()};
  .socials-col {
    .row {
      justify-content: end;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .image-col {
    img {
      height: 5rem;
    }
  }
  .social-col {
    width: fit-content;
    h6 {
      font-size: 26px !important;
    }
  }
  .top-row {
    padding: 3rem 0;
    justify-content: space-between;
  }
  .link-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > div {
      width: fit-content;
    }
    a {
      color: ${colors.white.getHex()};
    }
  }
  .wmk-layout-copyright {
    border-top: 2px solid ${colors.white.getHex()};
    padding: 1rem 0 2rem;
  }
  @media only screen and (max-width: 767px) {
    .link-col {
      order: 1;
      align-items: center;
      padding-bottom: 1rem;
    }
    .socials-col {
      order: 4;
      align-items: center;
      padding-top: 1rem;
    }
    .image-col {
      order: 3;
      display: flex;
      justify-content: center;
      padding: 1rem 0;
      img {
        object-fit: contain !important;
      }
    }
    .links-col {
      order: 1;
      margin-bottom: 2rem;
    }
    .site-link {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .social-col {
      h6 {
        font-size: 50px !important;
      }
    }
  }
`;

interface PlatformInterface {
  [k: string]: React.ReactElement;
}

const Footer = () => {
  const [viewWidth, setViewWidth] = useState(0);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
  };

  const data: FooterQuery = useStaticQuery(query);
  const logoImage = new Img(data.logo);
  const platforms: PlatformInterface = {
    facebook: <FaFacebookF />,
    twitter: <FaTwitter />,
    youtube: <FaYoutube />,
    linkedin: <FaLinkedinIn />
  };

  const socials = (
    <Row>
      {data.social?.links &&
        data.social?.links.length &&
        data.social.links.map((link, i) => {
          const { title, url } = link;
          const target = link.target === "New Window" ? "blank" : "self";
          return (
            <Col className="social-col" xs={12} key={link.title + i}>
              <WmkLink
                key={title}
                to={url}
                target={target}
                title={"Visit " + title + " page"}>
                <Typography.H6 style={{ color: "white" }}>
                  {platforms[link.linkText.toLocaleLowerCase()]}
                  <span className="sr-only sr-only-focusable visually-hidden visually-hidden-focusable">
                    {"Visit " + title.toLocaleLowerCase() + " page"}
                  </span>
                </Typography.H6>
              </WmkLink>
            </Col>
          );
        })}
    </Row>
  );

  const links = (
    <Col>
      <Container>
        <Row>
          {data.siteLinks?.links &&
            data.siteLinks?.links.length &&
            data.siteLinks.links.map((link, i) => {
              const { title } = link;
              const target = link.target === "New Window" ? "blank" : "self";
              return (
                <Col className="site-link" xs={12} key={link.title + i}>
                  <WmkLink
                    key={title}
                    to={link.url.includes("self://") ? link.url.replace("self://", "/") : link.url}
                    target={target}
                    title={"Visit " + title + " page"}>
                    <Typography.BLOCKQUOTE
                      style={{
                        color: "white",
                        fontFamily: "Raleway",
                        textTransform: "uppercase",
                        fontWeight: 700
                      }}>
                      {link.linkText}
                    </Typography.BLOCKQUOTE>
                  </WmkLink>
                </Col>
              );
            })}
        </Row>
      </Container>
    </Col>
  );

  return (
    <FooterWrapper>
      <Container>
        <Row className="top-row">
          <Col className="image-col" xs={12} md={"auto"}>
            <WmkImage image={logoImage} />
          </Col>
          {viewWidth <= 767 && (
            <Col className="socials-col" xs={12}>
              {socials}
            </Col>
          )}
          {viewWidth <= 767 && (
            <Col className="links-col" xs={12}>
              {links}
            </Col>
          )}
          <Col className="link-col" xs={12} md={3}>
            <Typography.FOOTER>
              2 Arnolt Dr. Pierceton, IN 46562
            </Typography.FOOTER>
            <Typography.FOOTER>
              <WmkLink tel>(574) 594-5544</WmkLink>
            </Typography.FOOTER>
            <Typography.FOOTER>
              <WmkLink mailto>lights@choosedmmg.com</WmkLink>
            </Typography.FOOTER>
            {viewWidth > 767 && <>{socials}</>}
          </Col>
        </Row>
        <Row>
          <Col className="copyright-col">
            <Copyright
              style={{ borderTop: `2px solid ${colors.white.getHex()} ` }}>
              {data.site.siteMetadata.title}, LLC. All rights reserved.
            </Copyright>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;

interface FooterQuery {
  site: SiteMetaDataFields;
  logo: ContentfulImageQuery;
  social: MenuQuery;
  siteLinks: MenuQuery;
}

const query = graphql`
  {
    site {
      ...NodeSiteMetadata
    }
    logo: contentfulAsset(
      title: { eq: "Christmasland-Logo-RGB-White-150dpi" }
    ) {
      ...NodeImageQuery
    }
    social: contentfulMenu(title: { eq: "Social Media" }) {
      ...NodeMenuFields
    }
    siteLinks: contentfulMenu(title: { eq: "Footer Menu" }) {
      ...NodeMenuFields
    }
  }
`;
